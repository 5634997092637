const data = {
    'CodeAntAI': {'name': "CodeAntAI",'website': 'https://www.codeant.ai/','tagline': "AI to auto-fix bad code & security vulnerabilities.",'description': "We're like SonarSource or Veracode, but we don't just detect bad code; we auto-fix it. We are SOC 2 compliant, and are live in small teams to large unicorns.",'video_url': "https://www.youtube.com/embed/Znq7n98rHGo",'Category': "Code Assistants",'Founded': "2023",'Founders': "Amartya Jha and Chinmay Bharti",'Special_Features':"Pull Request Reviewer, Code Health Dashboard, Shift Left Instantly",'Pricing_Plans': "$19/user/month - $39/user/month",'icon': "https://bookface-images.s3.amazonaws.com/logos/83de75f793dbe37ebdabab0b1ea7a65b32c2432e.png",},
    'Fluently':{'name': 'Fluently', 'tagline': 'AI-powered speaking coach for calls', 'description': 'Fluently is an app that helps non-native professionals improve their English with instant feedback after each Zoom call. Imagine having a personal coach, always listening and providing tips.', 'Category': 'AI Agents', 'video_url': 'https://www.youtube.com/embed/4O-zjeu_slw?si=TkRUkcU3b-y3z-mK', 'Founded': 2023.0, 'Founders': 'Yurii Rebryk, Stanislav Beliaev', 'Special_Features': 'Learn on the go, Personalized insights, Actionable feedback', 'Pricing_Plans': 'Contact Us', 'website': 'https://getfluently.app', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/db75913515757a481b98e83535c57ea70d7b0fd8.png'},
    'Basepilot':{'name': 'Basepilot', 'tagline': 'AI coworkers to automate browser work, in minutes.', 'description': 'Basepilot builds AI coworkers that collaborate with humans to automate work across the browser. They learn from what you do and you can teach them new skills, just by demonstration.', 'Category': 'AI Agents', 'video_url': 'https://www.youtube.com/embed/TxvHeF--NU4?si=wqv_-Ax1kV8zzFS8', 'Founded': 2024.0, 'Founders': 'Ken Hendricks, Pascal Wieler', 'Special_Features': 'Sales, Recruitment, Finance, Any other manual task', 'Pricing_Plans': 'Contact Us', 'website': 'https://www.basepilot.com/', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/2ab7f5055bddb0617e16226359b7395d5b8354b2.png'},
    'Marr Labs': {'name':'Marr Labs','tagline': 'AI-voice agents that are indistinguishable from humans.', 'description': 'Out of the box, Vox just works. This frees up your workforce to focus on critical issues.', 'Category': 'AI Agents', 'video_url': 'https://www.youtube.com/embed/UWaAL1MSOZ8?si=82X3QULIv7bBd6CS', 'Founded': 2023.0, 'Founders': 'Dave Grannan and Han Shu', 'Special_Features': 'Scheduler, Lead pre-qualification, Data acquisition, Sales agent, Customer service agent, Personal assistant', 'Pricing_Plans': 'Contact Us', 'website': 'https://www.marrlabs.com/', 'icon': 'https://bookface-images.s3.amazonaws.com/logos/ae38baa345d785a0b16c12dceb9a7089620d0f69.png'},
    'Powder':{'name': 'Powder', 'tagline': 'Sales platform for wealth advisors', 'description': 'Powder is an AI tool that helps wealth advisors rapidly create sales proposals that are personalized for each prospective client.', 'Category': 'AI Agents', 'video_url': 'https://www.loom.com/embed/dc39064983f7419da5d18487bdcf66ca', 'Founded': 2023.0, 'Founders': 'Kanishk Parashar, Soo Yung Cho, David Goerz, Dominic Tully', 'Special_Features': 'Brokerage, tax and estate document parser, Meeting notetaker, Portfolio analysis tool', 'Pricing_Plans': '$500/user/month', 'website': 'https://www.powderfi.com/index.html', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/c6603ab4de90057f1854b7b4b75d28ec647c3aa7.png'},
    'OpenFoundry':{'name': 'OpenFoundry', 'tagline': 'The fastest developer experience for building on open source AI.', 'description': 'OpenFoundry is an open source platform that helps engineers build, deploy, and scale their open source AI stack 10x faster. Ship your AI stack with just one line of code.', 'Category': 'Code Assistants', 'video_url': 'https://www.youtube.com/embed/49ay9Gqbc1U?si=7XsBZn7COwAamWNK', 'Founded': 2023.0, 'Founders': 'Tyler Lehman, Arthur Chi', 'Special_Features': 'Ship to your cloud, Find the best models, Prototype and fine-tune', 'Pricing_Plans': 'Contact Us', 'website': 'https://www.openfoundry.ai', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/7ce4129c96ae12a1f36222fb4e03f8ee4c1b0306.png'},
    'Veles':{'name': 'Veles', 'tagline': 'Copilot for Enterprise Software Sales', 'description': 'Introducing Veles – your copilot for enterprise software sales. Veles empowers sales reps to comprehend and convey pricing effectively, crafting dynamic quotes that maximize deal size.', 'Category': 'AI Agents', 'video_url': 'https://www.loom.com/embed/5ed62a735cd04031a79062374185e6cf', 'Founded': 2023.0, 'Founders': 'Simon Ooley, Patrick Koperwas', 'Special_Features': 'SaaS Pricing Calculator, Standard Deal Definition, Performance Analytics, Alignment & Optimization', 'Pricing_Plans': 'Contact Us', 'website': 'https://www.getveles.com', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/f31690807aa0caf76a46157dc028528285b5989d.png'},   
    
    'Sonauto':{'name': 'Sonauto', 'tagline': 'Create hit songs with AI', 'description': 'Sonauto is an AI music editor that turns prompts, lyrics, or melodies into full songs in any style. For example, in a few minutes with Sonauto you can make an original birthday song for your friend, sung by Frank Sinatra.', 'Category': 'Multimedia Generation', 'video_url': 'https://www.youtube.com/embed/NFSsA5P4OUw?si=gS7QLI91Pn-fOcCr', 'Founded': 2023.0, 'Founders': 'Ryan Tremblay, Hayden Housen', 'Special_Features': 'Text-to-music service, Share, Comment, Remix the creations of others', 'Pricing_Plans': 'Contact Us', 'website': 'https://sonauto.ai/Home', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/30eb9790545896983b2bcc8e7e3026af2fc31212.png'},
    'Amber AI':{'name': 'Amber AI', 'tagline': 'Co-pilot for Account Managers', 'description': 'Amber is an extension of your post-sales team that has context on every customer and every action. ', 'Category': 'AI Agents', 'video_url': 'https://www.youtube.com/embed/DTjH5RTE4Lo?si=nJmS45sKH3we4ujt', 'Founded': 2024.0, 'Founders': 'Oliver Zou, Helen Wu', 'Special_Features': 'Automate reactive responses, Supercharge proactive outreach', 'Pricing_Plans': 'Contact Us', 'website': 'https://www.helloamber.ai', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/ff0aee737867b4cc208ed70d985fdd52233ec5bc.png'},
    'Alai':{'name': 'Alai', 'tagline': 'Create high quality presentations faster using AI', 'description': 'Alai streamlines presentation creation for tech professionals with AI. Users input plain text proposals and receive polished slide suggestions, saving time on design work.', 'Category': 'Multimedia Generation', 'video_url': 'https://www.getalai.com', 'Founded': 2023.0, 'Founders': 'Anmol Sood, Krishna Gupta', 'Special_Features': 'Get multiple slide options, Built-in iteration loop with AI', 'Pricing_Plans': 'Contact Us', 'website': 'https://www.getalai.com', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/42e82b3576df0a2209e04cffa66aeabe46f8a18a.png'},
    'PocketPod':{'name': 'PocketPod', 'tagline': 'AI generated podcasts tailored to your interests', 'description': "PocketPod creates AI generated podcasts tailored to your interests. Whether it's a daily news update or a deep dive on a specific topic, we allow people to consume information in a familiar podcast format. ", 'Category': 'Multimedia Generation', 'video_url': 'https://www.youtube.com/embed/lyGZMiLgk9Q?si=EtbbaiKpq8VdpIjw', 'Founded': 2023.0, 'Founders': 'Ross Cefalu, Davis Palmie', 'Special_Features': 'New episode every morning, All the stories in one place, RSS feed available', 'Pricing_Plans': 'Contact Us', 'website': 'https://pocketpod.app', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/0612cd97da14553a006a76f68c9cb26fb2404704.png'},
    'Opencall Ai':{'name': 'Opencall Ai', 'tagline': 'AI call centers for any business', 'description': 'Opencall lets businesses answer the phone automatically. Our AIs answer questions, book appointments, and integrate with existing software.', 'Category': 'AI Agents', 'video_url': 'https://www.youtube.com/embed/2nI7yFp7iwc?si=WfbAeZRAB38xQ00N', 'Founded': 2022.0, 'Founders': 'Oliver Silverstein, Nicholas Lee, Eric Mei, Arthur Silverstein', 'Special_Features': 'Answer questions, Book appointments, and Integrate with existing software', 'Pricing_Plans': 'Contact Us', 'website': 'https://opencall.ai', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/41e58d6b3e2399554a397da9ec33195fe091ac9e.png'},
    
    'PandasAI':{'name': 'PandasAI', 'tagline': 'PandasAI is an open-source conversational data analysis platform with…', 'description': 'PandasAI is an open-source library for conversational data analysis. Enterprises can connect their dataframes, databases or datalakes and do data analysis in plain english.', 'Category': 'AI-powered Analytics', 'video_url': 'https://www.youtube.com/embed/k-wVnGkBI7E?si=cm2CgbCahIWeWVD-', 'Founded': 2023.0, 'Founders': 'Gabriele Venturi', 'Special_Features': 'Visualize data, Ask complex queries, Extract valuable insights', 'Pricing_Plans': 'Free to €29/month', 'website': 'https://pandas-ai.com', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/6eea04f473e3424dc0c2177031cb6829296ee867.png'},
    'Kater Ai':{'name': 'Kater Ai', 'tagline': 'The first diagnostic analytics platform', 'description': 'Kater makes it possible for executives to understand why business outcomes occur in a couple seconds. ', 'Category': 'AI-powered Analytics', 'video_url': 'https://www.youtube.com/embed/hiF-F0W61Dw?si=mnWZ5imhs1EqiDdX', 'Founded': 2023.0, 'Founders': 'Yvonne Chou, Robin Seitz', 'Special_Features': 'Generate hypotheses, Writes the queries to validate those hypotheses, and finds any insights from the queries.', 'Pricing_Plans': 'Contact Us', 'website': 'https://www.kater.ai', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/63fad7eb7b1de3738cfe2f3304a5b0b48aecb474.png'},
    'Upsolve AI':{'name': 'Upsolve AI', 'tagline': 'Customer-facing analytics as a service, powered by AI.', 'description': 'We are building a full data stack that enables businesses to build and offer analytics to their customers at lightning speed and gives their customers the superpower to answer any data questions via AI.', 'Category': 'AI-powered Analytics', 'video_url': 'https://www.youtube.com/embed/Vobh2hw_Pgk?si=RVjbIESJZ_PVcEN0', 'Founded': 2023.0, 'Founders': 'Ka Ling Wu, Serguei Balanovich', 'Special_Features': 'Seamless connections, Effortless Visualizations, Guided Action', 'Pricing_Plans': 'Contact Us', 'website': 'https://upsolve.ai', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/584af51898d885eead8c1806789fece6d7acaff0.png'},
    'Quary': {'name': 'Quary', 'tagline': 'The first in-browser analytics engineering platform', 'description': 'Quary is the first analytics engineering platform that brings the entire model-test-deploy workflow into the browser. ', 'Category': 'AI-powered Analytics', 'video_url': 'https://www.youtube.com/embed/MD6In-iUd9g?si=wkj7PUKs8_Q1wZZs', 'Founded': 2023.0, 'Founders': 'Benjamin King, Louis Jordan', 'Special_Features': 'Connect to your data warehouse, Organise your Business Intelligence as SQL, Collaborate securely, Deploy models', 'Pricing_Plans': 'Free', 'website': 'https://www.quary.dev', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/e54d14cc55231dd1bd8f1cfd85b8f8366f88fae7.png'}, 
    'Octolane AI': {'name': 'Octolane AI', 'tagline': 'Building AI-first Salesforce', 'description': 'Building AI-first Salesforce. Our CRM does a lot more than just storing customer data. It uses AI to find the best customers, reach out to them and close them.', 'Category': 'AI-powered Analytics', 'video_url': 'https://www.youtube.com/embed/oqrVDTL8LS8?si=uBPT8ibrmfF796SK', 'Founded': 2024.0, 'Founders': 'One Chowdhury, Md Abdul Halim Rafi', 'Special_Features': 'Company Enrichment, Email Sending, Real-time Email & Calendar Sync, Lead Identify, PostHog, Segment & Slack Integrations', 'Pricing_Plans': '$1000/month', 'website': 'https://www.octolane.com', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/a1549de8fd9953675de16d8b1fa448b4b2c204b6.png'}, 
    'Pretzel AI':{'name': 'Pretzel AI', 'tagline': 'Open-source & AI-native replacement for Jupyter Notebooks', 'description': "Pretzel fixes Jupyter's problems with native AI-tooling, collaboration, modern code-editing features, native SQL support and a visual, AI-assisted data exploration and dashboarding suite.", 'Category': 'AI-powered Analytics', 'video_url': '', 'Founded': 2024.0, 'Founders': 'Prasoon Shukla, Ramon Garate', 'Special_Features': 'AI-Powered, Reproducibility, Collaboration, IDE-like Experience, Polyglot, Open-Source', 'Pricing_Plans': 'Contact Us', 'website': 'https://withpretzel.com', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/1cf7e56ca345b5a9b329ec635ac2f39ef918b613.png'},

    'Focal': {'name': 'Focal', 'tagline': 'AI video creation tool for TV shows and movies', 'description': 'Focal is an AI video creation tool that allows anyone to create and share TV episodes and movies. We combine existing video, audio, and language models to make this process simple and straightforward.', 'Category': 'Multimedia Generation', 'video_url': '', 'Founded': 2023.0, 'Founders': 'Robert Cunningham, Felix Wang', 'Special_Features': 'Adapt books into screenplays, Generating video and audio clips, Keeping characters consistent, Lip-syncing characters', 'Pricing_Plans': 'Contact Us', 'website': 'https://focalml.com', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/9ec08eef8dbe4db1ca30529a97bf9fde4e2b61aa.png'}, 
    'ego': {'name': 'ego', 'tagline': 'AI-native 3D simulation engine', 'description': 'We’re building the first generative ai-powered simulation engine, where non-technical creators can generate realistic characters powered by LLMs, 3D worlds, and interaction code/scripts with just prompts.', 'Category': 'Multimedia Generation', 'video_url': 'https://www.youtube.com/embed/bLpG4LrLf60?si=hIAeeQbGIhjp9R4R', 'Founded': 2023.0, 'Founders': 'Vishnu Hari, Peggy Wang', 'Special_Features': 'Character personalities, Conversations, Memories, and Routines/movements in games', 'Pricing_Plans': 'Contact Us', 'website': 'https://www.ego.live', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/ccbeabe74e35bc254e82e981b4fca69945e24730.png'},
    'Eggnog': {'name': 'Eggnog', 'tagline': 'Youtube for AI-generated content', 'description': 'Eggnog is the Youtube for AI-generated content. The initial product is the only AI video platform on the market that generates consistent characters.', 'Category': 'Multimedia Generation', 'video_url': 'https://www.youtube.com/embed/WnAzSG9PFO8?si=OWNoFryVF0FqUM4R', 'Founded': 2024.0, 'Founders': 'Jitesh Maiyuran, Sam Plank', 'Special_Features': 'Create Characters, Storyboard, Generate videos', 'Pricing_Plans': 'Contact Us', 'website': 'https://www.eggnog.ai', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/b8db4ae69ffa9fe27a2e056c7825fa07368367d7.png'}, 
    'Ellipsis ': {'name': 'Ellipsis ', 'tagline': 'Convert technical instructions into working, tested code', 'description': 'With Ellipsis, teams get a deep LLM-powered code review on every commit. When a developer wants to make a change, they simply tag us, and Ellipsis submits the fix.', 'Category': 'Code Assistants', 'video_url': 'https://www.loom.com/embed/3c03d542f5d04df19946c770f8db3661', 'Founded': 2023.0, 'Founders': 'Hunter Brooks, Nick Bradford', 'Special_Features': 'Automated Code Review, Pull-requests-as-a-service, Address PR comments', 'Pricing_Plans': 'Contact Us', 'website': 'https://www.ellipsis.dev', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/825caa6d8502745ed1854595552f4f7f1091bb3e.png'}, 
    'Decipher AI': {'name': 'Decipher AI', 'tagline': 'Helping engineers understand and fix production bugs', 'description': 'Decipher AI lets engineers identify and resolve production issues by analyzing runtime data and product behavior with AI. Companies use us to monitor their application and to quickly understand what went wrong, the user impact, and how to fix it.\r', 'Category': 'Code Assistants', 'video_url': 'https://www.youtube.com/embed/mqzt1JrbxQM?si=pFwM5MREzAUvzenL', 'Founded': 2023.0, 'Founders': 'Michael Rosenfield, Rohan Das', 'Special_Features': 'All the context in one place, AI powered user impact summaries, AI fast debugging', 'Pricing_Plans': 'Contact Us', 'website': 'https://getdecipher.com', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/29585cd58ffa28775d545a62edb03b71fdea79f2.png'}, 
    'Keywords AI': {'name': 'Keywords AI', 'tagline': 'Unified DevOps platform to build software with AI.', 'description': 'The only platform you need to launch and optimize your LLM application. Integration is dead simple - everything comes with 2 lines of code', 'Category': 'Code Assistants', 'video_url': 'https://keywordsai.co/images/Dashboard.png', 'Founded': 2023.0, 'Founders': 'Andy Li, Raymond Huang', 'Special_Features': 'Infrastructure and Deployment, Model Playground, Prompt Management, Request Logging, Performance Evaluations, Usage Dashboard and Analytics, Data Curation and Fine-Tuning', 'Pricing_Plans': 'Free to $499/month', 'website': 'https://keywordsai.co/#/', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/3f60a49b454abcbf399c1110eb888b6aa3b0627f.png'},
    'ion design': {'name': 'ion design', 'tagline': 'Instantly converts designs into clean code', 'description': 'ion design is building a new layer on the software development stack, automating front-end engineering through a design system and code generation.', 'Category': 'Code Assistants', 'video_url': 'https://www.youtube.com/embed/81kUjZUXaxQ?si=_UpVIt7Va7GZHaUA', 'Founded': 2023.0, 'Founders': 'Samraaj Bath, Samuel Barnholdt', 'Special_Features': 'Pixel-perfect code, Multifile components, Conditional logic and stub functions', 'Pricing_Plans': 'Contact Us', 'website': 'https://www.ion.design', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/19ac79a5dd7e79b8d3e0a7794a401a7673116ee9.png'}, 

    'Zaymo': {'name': 'Zaymo', 'tagline': 'Interactive email builder for ecommerce brands', 'description': "Recent changes in email clients made it possible to embed a whole website inside an email. We've built the first email builder that lets Shopify brands create emails that are fully interactive like websites. ", 'Category': 'AI-led Marketing', 'video_url': 'https://www.youtube.com/embed/ToLPqStUMiY?si=LkxeKW1SUHwLv50e', 'Founded': 2023.0, 'Founders': 'Brice Douglas, Santiago Gomez Paz, Daniel Jones', 'Special_Features': 'In-email abandoned cart recovery, One-click subscription upsell, Campaigns, Review collection, Forms', 'Pricing_Plans': 'Contact Us', 'website': 'https://zaymo.com', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/ffeb1af9c08c8389614114b183fb157758499ef6.png'}, 
    'Firebender': {'name': 'Firebender', 'tagline': 'Find the best leads to sell to', 'description': 'With Firebender, you can qualify any company using natural language over scraped websites, job listings, linkedin posts, and more.', 'Category': 'AI-led Marketing', 'video_url': 'https://www.loom.com/embed/971a9b1a0d13481daad45d439f7d0b71', 'Founded': 2023.0, 'Founders': 'Aman Gottumukkala, Kevin Tang', 'Special_Features': 'Ask questions, Find deeper insights, Qualify leads.', 'Pricing_Plans': '$199-599/month', 'website': 'https://firebender.com', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/2e115e9ecb462ccede05b8f40b4dc09bb024f6b5.png'}, 
    'Plai': {'name': 'Plai', 'tagline': 'Run ads with AI', 'description': 'Plai is the AI advertising tool built for marketing teams and agencies. Thousands of advertisers are using Plai to create Facebook Ads, Google Ads, and TikTok Ads from a ChatGPT prompt.', 'Category': 'AI-led Marketing', 'video_url': 'https://www.youtube.com/embed/Uch3KC9YkHE?si=bPVIymUmpjmjrQGZ', 'Founded': 2020.0, 'Founders': 'Logan Welbaum', 'Special_Features': 'Automated ad improvements, Analytics supported for insights, Create ad templates with AI, AI Landing Page Builder ', 'Pricing_Plans': 'Contact Us', 'website': 'https://www.plai.io', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/9e5edb26e5bdc44b210c50ed3cc68204303afbc9.png'}, 
    '1stCollab': {'name': '1stCollab', 'tagline': 'Performance-Optimized Influencer Marketing', 'description': '1stCollab is an AI-powered influencer marketing platform for helping brands optimize performance. ', 'Category': 'AI-led Marketing', 'video_url': '', 'Founded': 2022.0, 'Founders': 'Leon Lin, Varun Bansal, Carina Cater, Andrew Liu', 'Special_Features': 'Campaign launch, Real time metrics update, A custom shareable link', 'Pricing_Plans': 'Contact Us', 'website': 'https://www.1stcollab.com', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/38bfe8c1abadf254cda7e353f68089f57109eaec.png'}, 
    'Penguin AI': {'name': 'Penguin AI', 'tagline': 'AI-Powered Inbound Sales Platform', 'description': 'Penguin AI is an inbound sales platform that identifies, engages, and converts leads visiting your website into demos.', 'Category': 'AI-led Marketing', 'video_url': 'https://www.youtube.com/embed/jPCwcvVu3-A?si=cmU01z5RnPBikPWa', 'Founded': 2022.0, 'Founders': 'Rohan Doshi', 'Special_Features': 'Lead Feed, Workflows, AI Chat', 'Pricing_Plans': '$499-999/month', 'website': 'https://www.penguinai.app', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/a179ed55776fa8b30a32019584bd1e644d3018a9.png'}, 
    'Kiosk' : {'name': 'Kiosk', 'tagline': 'AI powered WhatsApp Marketing platform', 'description': 'In short, we blend AI agents and traditional marketing tools (Campaigns, Workflows) to generate personalised conversations at scale. Think of us like the MailChimp for WhatsApp, with a conversational AI twist', 'Category': 'AI-led Marketing', 'video_url': '', 'Founded': 2023.0, 'Founders': 'Paul Lafforgue, Thomas Sohet', 'Special_Features': 'Build automations in Kiosk, Send an interactive campaign', 'Pricing_Plans': '$100-500/month', 'website': 'https://www.kiosk.so', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/c7b8da61a468644beaadb1f98361270cd5fafb99.png'},
    

    'Quivr': {'name': 'Quivr', 'tagline': 'Open Source Chat-Powered Second Brain', 'description': 'Quivr is an AI open-source platform where enterprises connect to their tools, docs, APIs and databases to chat with them. ', 'Category': 'AI Chatbots', 'video_url': 'https://www.youtube.com/embed/jvNYWiXvkds?si=MZYoclVimUbUn7uh', 'Founded': 2024.0, 'Founders': 'Stan Girard, Antoine Dewez, Brian Laleye', 'Special_Features': 'Automatically write an email, Extract actionable information, Summarise', 'Pricing_Plans': 'Free to $15/seat/month', 'website': 'https://www.quivr.app', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/55e2d5d59eda45392b5f80742e4d67462860257e.png'}, 
    'Danswer': {'name': 'Danswer', 'tagline': 'Open Source AI Assistant and Enterprise Search', 'description': 'Danswer is the AI assistant that connects to all your company tools and docs and makes finding any piece of information a breeze.', 'Category': 'AI Chatbots', 'video_url': 'https://www.youtube.com/embed/hqSouur2FXw?si=e16MunWfudHwOq3e', 'Founded': 2023.0, 'Founders': 'Yuhong Sun, Chris Weaver', 'Special_Features': "Avoid model provider lock-in, Leverage your LLM's internal knowledge, AI responses that reference real sources, Built for Slack, Host anywhere, Create tailored personas", 'Pricing_Plans': 'Free to $16/seat/month', 'website': 'https://www.danswer.ai', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/a6f88d118408e0d4b09770e0ebb02f3ff2d95a83.png'}, 
    'Zep AI': {'name': 'Zep AI', 'tagline': 'Long-Term Memory for AI Assistants', 'description': 'Zep is the easiest way to add long-term memory to your AI Assistant. With Zep, you can recall, understand, and extract data from chat histories, enabling you to build rich, personalized experiences.', 'Category': 'AI Chatbots', 'video_url': 'https://www.youtube.com/embed/qVspUE_R-iI?si=1SQ0i_Mhwjzhi7TQ', 'Founded': 2023.0, 'Founders': 'Daniel Chalef', 'Special_Features': 'Perpetual Memory, Dialog Classifier, Structured Data Extraction', 'Pricing_Plans': 'Free to $285/month', 'website': 'https://www.getzep.com', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/01c6d0585e8c71ae2a60d4a67e5c15a449165704.png'},
    'Rex': {'name': 'Rex', 'tagline': 'Your personal AI fitness and nutrition coach', 'description': 'Rex.fit (formerly BabylonAI) is an AI fitness and nutrition coach. We use computer vision and AI to create interactive gamified workouts. We also offer a nutrition chatbot that simplifies food logging.', 'Category': 'AI Chatbots', 'video_url': '', 'Founded': 2023.0, 'Founders': 'Rangel Milushev, Ahmad Roumie', 'Special_Features': 'Track calories, Get workout suggestions, Get daily reports and feedback', 'Pricing_Plans': 'Free', 'website': 'https://www.rex.fit', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/1868ecd3b1ecd377ff3e39716f5202b3b4a837de.png'}, 
    'Sherloq': {'name': 'Sherloq', 'tagline': 'ChatGPT customized for any organization’s internal database', 'description': "Sherloq is like ChatGPT and Co-pilot for any organization's internal database. Data teams use us as their single source of truth on top of their existing data stack.", 'Category': 'AI Chatbots', 'video_url': '', 'Founded': 2022.0, 'Founders': 'Noy Twerski, Alon Greenfield, Nadav Gutman', 'Special_Features': 'Ask Any Data Question, Copilot & Auto-Suggestions, Metrics & SQL Repository, Integrates with your existing data stack', 'Pricing_Plans': 'Contact Us', 'website': 'https://www.sherloqdata.io/#', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/416d7b9190a2113a09b5de9e932713dbb12c2145.png'}, 
    'Inkeep': {'name': 'Inkeep', 'tagline': 'Turn your docs into a "ChatGPT"-like AI search and support copilot', 'description': 'Inkeep helps turn your docs, help center, and other content into an AI-powered search and chat copilot for you product. 100+ teams use Inkeep to improve user experience, deflect support questions, and identify gaps in their documentation.', 'Category': 'AI Chatbots', 'video_url': '', 'Founded': 2023.0, 'Founders': 'Nick Gomez, Robert Tran', 'Special_Features': 'Search bar for your docs, Chat widget for your app or landing page, Slack or Discord bot for your community', 'Pricing_Plans': '$120-299/month', 'website': 'https://inkeep.com', 'icon': 'https://bookface-images.s3.amazonaws.com/small_logos/72bfb13e14b0f43d68d0a7055eec27310d54f3c0.png'}
};

    



export default data;